<template>

  <el-form v-loading="loadingData" :model="objData" :rules="rules" ref="formRef">
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="row g-9 mb-7">

          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Phone</label>
            <el-form-item prop="phone" :rules="rules['field']">
              <el-input v-model="objData.phone" placeholder="Phone"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Email</label>
            <el-form-item prop="email" :rules="rules['field']">
              <el-input v-model="objData.email" placeholder="Email"></el-input>
            </el-form-item>
          </div>



          <div class="col-md-6 fv-row" v-for="language in languages">
            <label class="required fs-6 fw-bold mb-2">Place ({{ language }})</label>
            <el-form-item :prop="'place.' + language" :rules="rules['field']">
              <el-input type="textarea" v-model="objData.place[language]" :placeholder="`Place (${language})`" />
            </el-form-item>
          </div>

          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Instagram</label>
            <el-form-item prop="instagram">
              <el-input v-model="objData.instagram" placeholder="Instagram"></el-input>
            </el-form-item>
          </div>

          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Facebook</label>
            <el-form-item prop="facebook">
              <el-input v-model="objData.facebook" placeholder="Facebook"></el-input>
            </el-form-item>
          </div>

          <div class="col-md-6 fv-row">
            <label class="required fs-6 fw-bold mb-2">Twitter</label>
            <el-form-item prop="twitter">
              <el-input v-model="objData.twitter" placeholder="Twitter"></el-input>
            </el-form-item>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-9 mb-7">
      <div class="col-md-4 fv-row">

      </div>
      <div class="col-md-4 fv-row">
        <button @click="submitForm()" :data-kt-indicator="loading ? 'on' : null"
          class="btn btn-lg btn-primary full-width" type="button" :disabled="loading">
          <span v-if="!loading" class="indicator-label">
            Submit
          </span>
          <span v-if="loading" class="indicator-progress">
            Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </button>
      </div>
      <div class="col-md-4 fv-row">

      </div>
    </div>

  </el-form>

</template>

<script>
import { defineComponent, ref, toRefs, watch, onMounted } from "vue";
import { getIllustrationsPath } from "@/core/helpers/assets";
import { useRoute } from 'vue-router'
import ApiAxiosService from "@/core/services/ApiAxiosService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { handleError, handleSuccess } from "@/core/helpers/response";
import router from "@/router";
import { APIs } from "@/store/enums/ApiEnums";
import { getCities } from "@/core/helpers/remote_data";
import ImageComp from "@/components/admin/general/ImageComp";
import { useRouter } from 'vue-router'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "contact-us-comp",
  components: { ImageComp },
  props: ['type'],
  setup(props) {
    const router = useRouter();
    const { type } = toRefs(props);
    const route = useRoute();
    const formRef = ref(null);
    const objData = ref({});
    const loading = ref(null);
    const loadingData = ref(false);
    const multiLanguageFields = ref(['place']);
    const isIndeterminate = ref(true)
    const languages = ref(['en', 'ar']);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Contact Us", ['Settings']);
    });


    objData.value = {
      phone: '',
      email: '',
      instagram: '',
      facebook: '',
      twitter: '',
      place: {},
    };

    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    });
    const initData = async () => {
      let response = null;
      loadingData.value = true;
      try {
        response = await ApiAxiosService.get(APIs.SETTING.contact_us);
        objData.value = response.data.data;
      } catch (e) {
        handleError(e)
      }
      loadingData.value = false;

    }

    const submitForm = () => {

      formRef.value.validate((valid) => {
        if (valid) {
          updateResource();

        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }
    const updateResource = () => {
      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      loading.value = true;
      ApiAxiosService.post(APIs.SETTING.contact_us, formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }


    initData();


    return {
      getIllustrationsPath,
      formRef,
      objData,
      loading,
      loadingData,
      rules,
      initData,
      submitForm,
      updateResource,
      type,
      isIndeterminate,
      languages
    };
  },

});
</script>
